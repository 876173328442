
.tiblock {
  align-items: center;
  display: flex;
  height: 17px;
}

.ticontainer .tidot {
  background-color: #90949c;;
}
.ticontainer {
  display: inline-flex;
  margin-left: 4px
}

.tidot {
  -webkit-animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  -moz-animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  border-radius: 2px;
  display: inline-block;
  height: 4px;
  margin-right: 2px;
  width: 4px;
}

@keyframes mercuryTypingAnimation{
  0% { -webkit-transform:translateY(0px) }
  28%{ -webkit-transform:translateY(-5px) }
  44% { -webkit-transform:translateY(0px) }
}
@-webkit-keyframes mercuryTypingAnimation{
  0% { -webkit-transform:translateY(0px) }
  28%{ -webkit-transform:translateY(-5px) }
  44% { -webkit-transform:translateY(0px) }
}
@-moz-keyframes mercuryTypingAnimation{
  0% { -webkit-transform:translateY(0px) }
  28%{ -webkit-transform:translateY(-5px) }
  44% { -webkit-transform:translateY(0px) }
}
.tidot:nth-child(1){
-webkit-animation-delay:200ms;
-moz-animation-delay:200ms;
animation-delay:200ms;
}
.tidot:nth-child(2){
-webkit-animation-delay:300ms;
-moz-animation-delay:300ms;
animation-delay:300ms;
}
.tidot:nth-child(3){
-webkit-animation-delay:400ms;
-moz-animation-delay:400ms;
animation-delay:400ms;
}